@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

html {
  block-size: 100vh;
  inline-size: 100vw;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(180deg, #101040 0%, #101d40 51.04%, #1e1133 100%),
    linear-gradient(0deg, #fff, #fff) !important;
  overflow: hidden;
  block-size: 100%;
}

#root {
  block-size: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* stylelint-disable-next-line selector-class-pattern */
section.sui-date-picker__dialog button[aria-disabled='true'] {
  color: #a0a5b2;
  opacity: 0.4;
}

.u-select {
  background: rgba(0 0 0 / 27%) !important;
}

.u-legend .u-label {
  user-select: none;
}

.u-legend .u-marker {
  border-radius: 50%;
}
